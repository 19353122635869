import fetchGraphqlApi from 'lib/config/fetch-graphql-api'
import Cookies from 'js-cookie'
import {
  checkoutCreateMutation,
  discountCodesUpdateCheckoutMutation,
} from 'lib/shopify/mutations/checkout'
import { Checkout as CheckoutType } from 'lib/shopify/types'

export default async function createCheckout({
  locale = 'us',
  lineItems,
  discountCodes,
  customAttributes,
}: {
  locale: string
  lineItems: { variantId: string; quantity: number; customAttributes?: any[] }[]
  discountCodes?: string[]
  customAttributes?: any[]
}): Promise<CheckoutType.Checkout> {
  // 活动折扣 cookie 全站生效，后端拿不到，所以暂时注释
  // const personaCookie = getPersonaCookie()
  // referral Buy
  const inviteCode = Cookies.get('inviteCode')
  const playModeId = Cookies.get('playModeId')
  if (inviteCode && playModeId) {
    customAttributes = customAttributes
      ? customAttributes.concat([
          { key: '_invite_code', value: inviteCode ? inviteCode : '' },
          { key: '_play_mode_id', value: playModeId ? playModeId : '' },
        ])
      : [
          { key: '_invite_code', value: inviteCode ? inviteCode : '' },
          { key: '_play_mode_id', value: playModeId ? playModeId : '' },
        ]
  }

  const { res, status } =
    await fetchGraphqlApi<CheckoutType.ShopifyCreateCheckoutOperation>({
      locale,
      query: checkoutCreateMutation,
      variables: {
        lineItems,
        customAttributes: customAttributes || [],
      },
    })

  const checkout = res?.checkoutCreate?.checkout

  if (discountCodes) {
    // Promise.all 会导致Shopify api 报接口调取间隔过低的错误
    // const promiseList = discountCodes.map((code) => {
    //   return fetchGraphqlApi<CheckoutType.ShopifyCheckoutDiscountApplyOperation>(
    //     {
    //       locale,
    //       query: discountCodesUpdateCheckoutMutation,
    //       variables: {
    //         checkoutId: checkout?.id,
    //         discountCode: code,
    //       },
    //       cache: 'no-store',
    //     }
    //   )
    // })
    // await Promise.all(promiseList)
    if (discountCodes?.[0]) {
      await fetchGraphqlApi<CheckoutType.ShopifyCheckoutDiscountApplyOperation>(
        {
          locale,
          query: discountCodesUpdateCheckoutMutation,
          variables: {
            checkoutId: checkout?.id,
            discountCode: discountCodes[0],
          },
          cache: 'no-store',
        }
      )
    }
    if (discountCodes?.[1]) {
      await fetchGraphqlApi<CheckoutType.ShopifyCheckoutDiscountApplyOperation>(
        {
          locale,
          query: discountCodesUpdateCheckoutMutation,
          variables: {
            checkoutId: checkout?.id,
            discountCode: discountCodes[1],
          },
          cache: 'no-store',
        }
      )
    }
    if (discountCodes?.[2]) {
      await fetchGraphqlApi<CheckoutType.ShopifyCheckoutDiscountApplyOperation>(
        {
          locale,
          query: discountCodesUpdateCheckoutMutation,
          variables: {
            checkoutId: checkout?.id,
            discountCode: discountCodes[2],
          },
          cache: 'no-store',
        }
      )
    }
    if (discountCodes?.[3]) {
      await fetchGraphqlApi<CheckoutType.ShopifyCheckoutDiscountApplyOperation>(
        {
          locale,
          query: discountCodesUpdateCheckoutMutation,
          variables: {
            checkoutId: checkout?.id,
            discountCode: discountCodes[3],
          },
          cache: 'no-store',
        }
      )
    }
    if (discountCodes?.[4]) {
      await fetchGraphqlApi<CheckoutType.ShopifyCheckoutDiscountApplyOperation>(
        {
          locale,
          query: discountCodesUpdateCheckoutMutation,
          variables: {
            checkoutId: checkout?.id,
            discountCode: discountCodes[4],
          },
          cache: 'no-store',
        }
      )
    }
  }

  return checkout!
}
